var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.slides)?_c('Container',{staticClass:"no-mobile-px",class:[_vm.$bem('images-carousel'), { 'images-carousel--darken-images': _vm.$b.d && _vm.data.darkenImages }]},[(_vm.data.title)?_c('Title',{attrs:{"title":_vm.data.title,"titleStyle":_vm.data.titleStyle}}):_vm._e(),_c('v-row',[_c('v-col',[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Carousel',_vm._b({attrs:{"loop":"","autoplay-delay":Number(_vm.data.autoplayDelay)}},'Carousel',
						_vm.$b.props({
							feather: {
								d: _vm.slides.length > this.slidesPerPageDesktop && 30,
								t: _vm.slides.length > 4 && 100,
								m: _vm.slides.length > 2 && 20,
							},
							showArrows: {
								d: _vm.slides.length > this.slidesPerPageDesktop && true,
								t: _vm.slides.length > 4 && true,
							},
							slidesPerPage: { m: 2, t: 4, d: this.slidesPerPageDesktop },
							peek: {
								d: _vm.slides.length > this.slidesPerPageDesktop && 60,
								t: _vm.slides.length > 4 && 30,
								m: _vm.slides.length > 2 && 40,
							},
							showDots: { mt: true },
							gutter: 0,
							paginateBySlide: { d: true },
							noDrag: { d: true },
						})
					,false),_vm._l((_vm.slides),function(item,i){return _c('div',{key:i,staticClass:"px-2 px-sm-3"},[_c('SafeLink',{attrs:{"to":_vm.getToLink(item)}},[(item.textEnabled && item.textPosition == 'above')?_c('div',{staticClass:"w100 text-center my-2",class:_vm.$bem('__text-above')},[_c('h3',{staticClass:"font-weight-bold font-1 strong"},[_vm._v(" "+_vm._s(item.textTitle)+" ")]),(item.textSubtitle)?_c('h4',{staticClass:"font-weight-medium font-0 base--text",class:{ 'subtitle-mobile': _vm.$b.mt }},[_vm._v(" "+_vm._s(item.textSubtitle)+" ")]):_vm._e()]):_vm._e(),_c('Media',{class:_vm.$bem('__img'),attrs:{"src":_vm.getImage(item),"width":"100%","imgClass":[
									_vm.data.imgBorder == 'border'
										? 'rounded-lg'
										: _vm.data.imgBorder == 'circle' && 'img-circle' ]}},[(item.textEnabled)?_c('div',{staticClass:"h100",class:[{ 'd-flex flex-column justify-center': item.textPosition == 'over' }]},[(item.textPosition == 'inside')?_c('div',{staticClass:"w100 h100 background-overlay pa-5 d-flex flex-column justify-center align-center white--text",class:[
											_vm.$bem('__text-inside'),
											_vm.data.imgBorder == 'circle'
												? 'img-circle'
												: _vm.data.imgBorder == 'border' && 'rounded-lg' ]},[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"font-weight-bold font-1"},[_vm._v(" "+_vm._s(item.textTitle)+" ")]),(item.textSubtitle)?_c('h4',{staticClass:"font-weight-medium font-0 mt-1",class:{ 'subtitle-mobile': _vm.$b.mt }},[_vm._v(" "+_vm._s(item.textSubtitle)+" ")]):_vm._e()])]):_c('div',{staticClass:"seo-text",class:_vm.$bem('__text-seo')},[_c('div',[_c('h3',[_vm._v(_vm._s(item.textTitle))]),(item.textSubtitle)?_c('h4',[_vm._v(_vm._s(item.textSubtitle))]):_vm._e()])])]):_vm._e()]),(item.textEnabled && item.textPosition == 'below')?_c('div',{staticClass:"w100 text-center my-2"},[_c('h3',{staticClass:"font-weight-bold font-1 strong"},[_vm._v(" "+_vm._s(item.textTitle)+" ")]),(item.textSubtitle)?_c('h4',{staticClass:"font-weight-medium font-0 base--text",class:{ 'subtitle-mobile': _vm.$b.mt }},[_vm._v(" "+_vm._s(item.textSubtitle)+" ")]):_vm._e()]):_vm._e()],1)],1)}),0)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }