<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			slideIndex: 0,
		}
	},
	computed: {
		aspectRatio() {
			if (this.$b.d) {
				switch (this.data.sliderType) {
					case 'high':
						return 1264 / 680

					case 'mid':
						return 1264 / 500

					case 'low':
						return 1264 / 297

					default:
						return 1264 / 200
				}
			} else {
				return 390 / 350
			}
		},
		carouselProps() {
			return this.$b.props({
				showArrows: true,
				showDots: { mt: true },
				noDrag: { d: true },
			})
		},
	},
	methods: {
		getIndex(index) {
			this.slideIndex = index
		},
		videoId(i) {
			return this.$b({ mt: i.imgMobile, d: i.img }).video?.data.videoId
		},
		isVideo(i) {
			return i.type == 'video'
		},
		textClass(transition, effect, textType) {
			if (transition) {
				switch (effect) {
					case 'up':
						return `${textType}-up`
					case 'down':
						return `${textType}-down`
					case 'zoom':
						return `${textType}-zoom`
					case 'fade':
						return `${textType}-fade`
					default:
						return null
				}
			} else return null
		},
		btnClass(transition, subtitle, effect) {
			if (transition && subtitle) {
				switch (effect) {
					case 'up':
						return 'btn-up'
					case 'down':
						return 'btn-down'
					case 'zoom':
						return 'btn-zoom'
					case 'fade':
						return 'btn-fade'
					default:
						return null
				}
			} else if (transition && !subtitle) {
				switch (effect) {
					case 'up':
						return 'subtitle-up'
					case 'down':
						return 'subtitle-down'
					case 'zoom':
						return 'subtitle-zoom'
					case 'fade':
						return 'subtitle-fade'
					default:
						return null
				}
			} else return null
		},
	},
}
</script>

<template>
	<Container v-if="data.slides.length" fluid>
		<LazyHydrate when-visible>
			<Carousel
				arrowsBgHalfRounded
				loop
				:autoplay-delay="data.autoplayDelay"
				v-bind="carouselProps"
				@slideIndex="getIndex($event)"
			>
				<div v-for="(i, index) of data.slides" :key="index" class="overflow-hidden w100">
					<SafeLink :to="i.link" :id="i.labelId" v-evt-click>
						<Media
							:src="$b({ mt: i.imgMobile, d: i.img })"
							:img-class="{ 'zoom-in': index == slideIndex && i.zoomEffect }"
							cover
							width="100%"
							:aspectRatio="aspectRatio"
							:videoProps="{
								videoAttrs: {
									autoplay: 'autoplay',
									muted: 'muted',
									loop: 'loop',
									playsinline: 'playsinline',
								},
							}"
							:youtube-video-props="{
								iframeAttrs: {
									allow: 'autoplay; encrypted-media',
									allowfullscreen: 'allowfullscreen',
									playsinline: 'playsinline',
								},
								urlOptions: {
									controls: 0,
									showinfo: 0,
									rel: 0,
									autoplay: 1,
									mute: 1,
									loop: 1,
									playlist: videoId(i),
								},
							}"
							:vimeo-video-props="{
								iframeAttrs: {
									allow: 'autoplay; encrypted-media',
									allowfullscreen: 'allowfullscreen',
									playsinline: 'playsinline',
								},
								urlOptions: {
									background: 1,
									muted: 1,
									autoplay: 1,
								},
							}"
						>
							<div
								class="h100 w100 d-flex justify-center flex-column align-center"
								:class="{ 'background-overlay': isVideo(i) && i.darkenSlide }"
							>
								<h2
									class="font-4 font-lg-16 font-md-7 font-weight-bold primary pa-1 text-center"
									:class="textClass(index == slideIndex, i.textEffect, 'title')"
									v-if="i.title"
								>
									{{ i.title }}
								</h2>
								<h3
									class="font-1 font-lg-8 font-md-5 font-weight-medium secondary pa-1 text-center"
									v-if="i.subtitle"
									:class="[
										textClass(index == slideIndex, i.textEffect, 'subtitle'),
										{
											'mt-2': i.title && !$b.m,
											'mt-1': i.title && $b.m,
										},
									]"
								>
									{{ i.subtitle }}
								</h3>
								<v-btn
									class="cta font-weight-bold"
									:xSmall="$b.m"
									:class="[
										btnClass(index == slideIndex, i.subtitle, i.textEffect),
										{
											'mt-5': !$b.m,
											'mt-1': $b.m,
										},
									]"
									v-if="i.cta"
									>{{ i.cta }}</v-btn
								>
							</div>
						</Media>
					</SafeLink>
				</div>
			</Carousel>
		</LazyHydrate>
	</Container>
</template>

<style scoped lang="scss">
::v-deep .ssr-carousel-mask > div {
	padding: 0 !important;
}

.background-overlay {
	background-color: #424242aa;
}

@mixin textAnimation($name, $percValue, $initialTransformValue: null, $finalTransformValue: null) {
	@keyframes #{$name} {
		0% {
			transform: $initialTransformValue;
			opacity: 0;
		}
		#{$percValue} {
			transform: $initialTransformValue;
			opacity: 0;
		}
		100% {
			transform: $finalTransformValue;
			opacity: 1;
		}
	}
}

.title-up {
	@include textAnimation(titleUp, 40%, translateY(30px), translateY(0));
	animation: titleUp 2s ease forwards;
}

.subtitle-up {
	@include textAnimation(subtitleUp, 50%, translateY(30px), translateY(0));
	animation: subtitleUp 2s ease forwards;
}

.btn-up {
	@include textAnimation(btnUp, 60%, translateY(30px), translateY(0));
	animation: btnUp 2s ease forwards;
}

.title-down {
	@include textAnimation(titleDown, 40%, translateY(-30px), translateY(0));
	animation: titleDown 2s ease forwards;
}

.subtitle-down {
	@include textAnimation(subtitleDown, 50%, translateY(-30px), translateY(0));
	animation: subtitleDown 2s ease forwards;
}

.btn-down {
	@include textAnimation(btnDown, 60%, translateY(-30px), translateY(0));
	animation: btnDown 2s ease forwards;
}

.title-zoom {
	@include textAnimation(titleZoom, 40%, scale(2), scale(1));
	animation: titleZoom 2s ease forwards;
}

.subtitle-zoom {
	@include textAnimation(subtitleZoom, 50%, scale(2), scale(1));
	animation: subtitleZoom 2s ease forwards;
}

.btn-zoom {
	@include textAnimation(btnZoom, 60%, scale(2), scale(1));
	animation: btnZoom 2s ease forwards;
}

.title-fade {
	@include textAnimation(titleFade, 40%);
	animation: titleFade 2s ease forwards;
}

.subtitle-fade {
	@include textAnimation(subtitleFade, 50%);
	animation: subtitleFade 2s ease forwards;
}

.btn-fade {
	@include textAnimation(btnFade, 60%);
	animation: btnFade 2s ease forwards;
}

::v-deep .zoom-in {
	animation: zoomIn 12s ease forwards;
}

@keyframes zoomIn {
	0% {
		scale: 1;
	}

	100% {
		scale: 1.3;
	}
}
</style>

