<script>
// import LazyHydrate from 'vue-lazy-hydration'
export default {
	metaInfo() {
		let metaInfo = { meta: [] }
		if (this.$route.meta.pageTitle) {
			metaInfo.title = this.$route.meta.pageTitle
			metaInfo.meta.push({ vmid: 'og:t', property: 'og:title', content: this.$route.meta.pageTitle })
		}
		if (this.$route.meta.description) {
			metaInfo.meta.push({ vmid: 'd', name: 'description', content: this.$route.meta.metaDescription })
			metaInfo.meta.push({
				vmid: 'og:d',
				property: 'og:description',
				content: this.$route.meta.metaDescription,
			})
		}
		return metaInfo
	},
	components: {
		// LazyHydrate,
	},
	data() {
		return {
			items: [],
		}
	},
	watch: {
		$route() {
			this.$meta().refresh()
			this.$trackers.pageView()
		},
	},
	async resolveData(context, to, from, next) {
		const { ssr } = context
		const { dynamicPageKey } = to.meta
		try {
			if (ssr) {
				let items = await ssr.loadViewData('dynamicPage', dynamicPageKey)
				ssr.shouldCache = true
				return { items }
			} else {
				const { apis, store } = context
				const { data } = await apis.shopApi.get('/dynamic-page-data', {
					loading: (v) => store.set('shop/routeLoading', v),
					query: { dynamicPageKey },
				})
				return { items: data.items }
			}
		} catch (err) {
			next(err)
		}
	},
	mounted() {
		this.$meta().refresh()
		this.$trackers.pageView()
	},
}
</script>

<template>
	<DynamicPageItems :items="items" :css-vars-key="$route.meta.dynamicPageKey" />
</template>

