<script>
export default {
	props: {
		data: Object,
	},

	computed: {
		hotspotData() {
			return this.data.hotspotsData
		},
	},

	methods: {
		getChildRef() {
			let child = this.$refs.hotspotItem.$refs.parentDiv
			return child.getBoundingClientRect()
		},
		divStyler() {
			return {
				height: `${this.getChildRef().height}px`,
			}
		},
	},
}
</script>

<template>
	<Container :fluid="data.fullWidth">
		<div
			v-if="hotspotData.title"
			:class="{
				'modern-row': hotspotData.titleStyle == 'modern',
				'text-center': hotspotData.titleStyle == 'short',
				'd-flex align-center justify-center': hotspotData.titleStyle == 'double',
			}"
		>
			<div class="col d-flex double-col pa-0 mr-2" v-if="hotspotData.titleStyle == 'double'">
				<div class="double"></div>
				<div class="double"></div>
			</div>
			<h2
				v-if="hotspotData.title"
				class="font-weight-bold header d-inline-block"
				:class="{
					modern: hotspotData.titleStyle == 'modern',
					short: hotspotData.titleStyle == 'short',
					'font-4': $b.td,
					'font-3': $b.m,
				}"
			>
				{{ hotspotData.title }}
			</h2>
			<div class="col d-flex double-col pa-0 ml-2" v-if="hotspotData.titleStyle == 'double'">
				<div class="double"></div>
				<div class="double"></div>
			</div>
		</div>

		<div
			v-if="hotspotData.subtitle"
			:class="{
				'text-center': hotspotData.titleStyle == 'short' || hotspotData.titleStyle == 'double',
				'mt-1': hotspotData.titleStyle == 'short',
			}"
		>
			<p class="mb-4 font-2 base--text">{{ hotspotData.subtitle }}</p>
		</div>
		<div class="d-flex" v-bind:style="{ columnGap: '10px', ...divStyler }">
			<HotspotItem :full-width="data.fullWidth" :hotspotData="hotspotData[1]" ref="hotspotItem" />
			<HotspotItem
				:full-width="data.fullWidth"
				:hotspotData="hotspotData[2]"
				double
				v-if="hotspotData[2]"
			/>
		</div>
	</Container>
</template>

<style scoped>
.modern-row {
	border-bottom: 1px solid var(--primary);
}

@media (max-width: 599.98px) {
	.modern-row {
		flex-wrap: nowrap;
	}
}

h2.modern::after {
	content: '';
	border-bottom: 4px solid var(--headertext);
	position: relative;
	width: 102%;
	display: block;
	bottom: -1px;
}

h2.short::after {
	content: '';
	border-bottom: 3.4px solid var(--headertext);
	position: relative;
	width: 20%;
	display: block;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 6px;
}

.double-col {
	flex-direction: column;
}

.double {
	height: 1.4px;
	background: var(--headertext);
	margin: 1px 0;
}
</style>

