var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.data.enableTabs && _vm.data.tabs && _vm.data.tabs.length) || (_vm.data.products && _vm.data.products.length))?_c('Container',{staticClass:"align-center no-mobile-px",class:(_vm.$b({ m: 'pa-0' }), _vm.$bem('products-board'))},[_c('Title',{attrs:{"title":_vm.data.title,"btn":{ link: _vm.data.link, linkText: _vm.data.linkText },"titleStyle":_vm.data.titleStyle}}),(!_vm.data.designType || _vm.data.designType === 'dynamic')?_c('v-row',{class:[_vm.data.enableTabs ? 'align-start' : 'align-center', _vm.data.inverted && 'flex-row-reverse']},[(_vm.data.imgEnabled)?_c('v-col',{staticClass:"py-0",class:{
				'col-3': _vm.$b.d && _vm.productsLength <= 4,
				'col-4': _vm.$b.d && _vm.productsLength > 4,
				'col-12': _vm.$b.mt,
			}},[_c('Media',{attrs:{"src":_vm.$b({ d: _vm.data.img, mt: _vm.data.imgMobile }),"cover":"","width":"100%","img-class":_vm.$b({ d: 'rounded-lg' })}},[(_vm.data.textImgEnabled)?_c('div',{staticClass:"h100 w100 d-flex flex-column align-center",class:[
						_vm.$b({ d: 'rounded-lg', d: _vm.textPosition, mt: 'justify-center' }),
						_vm.data.textImgOverlay && 'background-overlay' ]},[_c('h3',{staticClass:"font-2 font-sm-5 font-md-7 font-weight-bold strong"},[_vm._v(" "+_vm._s(_vm.data.titleImg)+" ")]),(_vm.data.ctaImg)?_c('Button',{staticClass:"cta--text px-2",class:{ 'outlined-mobile my-3': _vm.$b.m, 'outlined my-6': _vm.$b.td },attrs:{"to":_vm.data.linkImg,"text":""}},[_vm._v(" "+_vm._s(_vm.data.ctaImg)+" ")]):_vm._e()],1):_vm._e()])],1):_vm._e(),_c('v-col',{style:(_vm.data.imgEnabled && _vm.$b({ mt: 'z-index: 1' }))},[(!_vm.data.enableTabs)?_c('v-row',{class:[
					{ 'products-board--dense': _vm.productsLimit.length == 12 },
					_vm.data.imgEnabled && _vm.productsLimit.length < 4 && _vm.$b({ t: 'justify-center' }) ]},_vm._l((_vm.productsLimit),function(product,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center px-1",class:[_vm.colSize]},[_c('ProductCard',{staticClass:"w100",class:{ imgSmall: _vm.colSize == 'col-2' },attrs:{"type":_vm.cardType,"small":"","for-mobile-shop":"","product":product,"listId":_vm.listId,"listIndex":index,"verticalCard":!_vm.data.imgEnabled && _vm.productsLimit.length < 7 && _vm.$b.td
								? false
								: !_vm.data.imgEnabled && _vm.productsLimit.length == 1 && _vm.$b.m
								? false
								: _vm.data.imgEnabled && _vm.productsLimit.length <= 6 && _vm.$b.td
								? false
								: true}})],1)}),1):_c('div',[_c('v-tabs',{staticClass:"h100 carousel-multiple-pro__slider-top mb-3'",class:{
						'carousel-multiple-pro__slider-right': _vm.$b.d,
						'carousel-multiple-pro__top-underline': _vm.$b.d,
					},attrs:{"background-color":"transparent","show-arrows":"","centered":"","center-active":"","color":_vm.$vars.cta,"slider-color":_vm.$vars.cta},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(tabItem,n){return _c('v-tab',{key:n,attrs:{"active-class":"cta--text"}},[(_vm.data.titleType == 'icon')?_c('v-icon',[_vm._v(_vm._s(tabItem.titleIcon))]):(_vm.data.titleType == 'img')?_c('Media',{attrs:{"src":tabItem.titleImg}}):_c('span',[_vm._v(" "+_vm._s(tabItem.title)+" ")])],1)}),1)],1),_c('div',[_c('div',_vm._l((_vm.data.tabs),function(item,n){return _c('div',{key:n,staticClass:"tab",class:{ 'active-tab': _vm.tab == n }},[(_vm.tab == n)?_c('v-row',{staticClass:"pa-1",class:_vm.data.imgEnabled && item.products.length < 4 && _vm.$b({ t: 'justify-center' })},_vm._l((item.products),function(product,k){return _c('v-col',{key:product.id,staticClass:"d-flex justify-center px-1",class:[_vm.colSize]},[_c('ProductCard',{class:{ imgSmall: _vm.colSize == 'col-2' },attrs:{"type":_vm.cardType,"small":"","for-mobile-shop":"","product":product,"listId":_vm.listId,"listIndex":k,"verticalCard":!_vm.data.imgEnabled && _vm.productsLimit.length < 7 && _vm.$b.td
											? false
											: !_vm.data.imgEnabled && _vm.productsLimit.length == 1 && _vm.$b.m
											? false
											: _vm.data.imgEnabled && _vm.productsLimit.length <= 6 && _vm.$b.td
											? false
											: true}})],1)}),1):_vm._e()],1)}),0)])],1)],1):_c('div',{staticClass:"remove-card-alignment d-flex justify-center"},[(!_vm.data.enableTabs)?_c('v-row',{style:(_vm.masonryMaxWidth)},_vm._l((_vm.masonryProducts),function(group,n){return _c('v-col',{key:n,staticClass:"px-1",attrs:{"cols":12 / _vm.masonryCols}},_vm._l((group),function(product,k){return _c('div',_vm._g({key:k},_vm.hasHoverBtn ? _vm.bindHoverEv(n, k) : null),[_c('ProductCard',{ref:("card-" + n + "-" + k),refInFor:true,staticClass:"mb-2",style:({
							width: _vm.$b.m && '170px',
							height: _vm.currentHover == (n + "-" + k) ? _vm.currentHoverHeight : 'fit-content',
							'z-index': _vm.currentHover == (n + "-" + k) ? 1 : 0,
							position: 'relative',
						}),attrs:{"type":_vm.cardType,"for-mobile-shop":"","small":_vm.$b.m,"product":product,"listId":_vm.listId,"listIndex":n + k}})],1)}),0)}),1):_c('div',[_c('div',[_c('v-tabs',{ref:"tabs",staticClass:"h100 carousel-multiple-pro__slider-top mb-3'",class:{
						'carousel-multiple-pro__slider-right': _vm.$b.d,
						'carousel-multiple-pro__top-underline': _vm.$b.d,
					},attrs:{"background-color":"transparent","show-arrows":"","centered":"","color":_vm.$vars.cta,"slider-color":_vm.$vars.cta},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(tabItem,n){return _c('v-tab',{key:n,attrs:{"active-class":"transparent"}},[(_vm.data.titleType == 'icon')?_c('v-icon',[_vm._v(_vm._s(tabItem.titleIcon))]):(_vm.data.titleType == 'img')?_c('Media',{attrs:{"src":tabItem.titleImg}}):_c('span',[_vm._v(" "+_vm._s(tabItem.title)+" ")])],1)}),1)],1),_vm._l((_vm.data.tabs),function(item,n){return _c('div',{key:n,staticClass:"tab",class:{ 'active-tab': _vm.tab == n }},[(n == _vm.tab)?_c('v-row',{staticClass:"pa-1 pt-2",style:(_vm.masonryMaxWidth)},_vm._l((_vm.masonryProducts[n]),function(group,n){return _c('v-col',{key:n,staticClass:"px-1",attrs:{"cols":12 / _vm.masonryCols}},_vm._l((group),function(product,k){return _c('div',_vm._g({key:k},_vm.hasHoverBtn ? _vm.bindHoverEv(n, k) : null),[_c('ProductCard',{ref:("card-" + n + "-" + k),refInFor:true,staticClass:"mb-2",style:({
									height: _vm.currentHover == (n + "-" + k) ? _vm.currentHoverHeight : 'fit-content',
									'z-index': _vm.currentHover == (n + "-" + k) ? 1 : 0,
									position: 'relative',
								}),attrs:{"type":_vm.cardType,"small":_vm.$b.m,"product":product,"for-mobile-shop":"","listId":_vm.listId,"listIndex":n + k}})],1)}),0)}),1):_vm._e()],1)})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }