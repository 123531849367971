import { get } from 'vuex-pathify'

export default () => {
	return {
		data() {
			return {
				firstname: null,
				lastname: null,
				email: null,
				place: null,
				hasFirstnameField: true,
				loading: false,
				state: 'pending',
				validation: {},
			}
		},
		computed: {
			user: get('shop/user'),
		},
		methods: {
			async submit() {
				if (this.loading) return

				if (!this.hasFirstnameField && !this.firstname) {
					this.firstname = '-'
				}

				return await this.$shopApi.post({
					url: '/user-forms/newsletter',
					data: {
						data: { firstname: this.firstname, lastname: this.lastname, email: this.email },
						origin: window.location.pathname + window.location.search,
						place: this.place,
					},
					loading: (v) => (this.loading = v),
					onSuccess: () => {
						this.state = 'success'
					},
					onError: () => {
						this.state = 'error'
					},
					onValidation: ({ validation }) => (this.validation = validation),
				})
			},
		},
		watch: {
			user() {
				if (this.user) {
					this.email = this.user.accountEmail
					this.firstname = this.user.firstname
					this.lastname = this.user.lastname
				}
			},
		},
		mounted() {
			if (this.user) {
				this.email = this.user.accountEmail
				this.firstname = this.user.firstname
				this.lastname = this.user.lastname
			}
		},
	}
}

