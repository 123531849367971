<script>
export default {
	props: {
		data: Object,
	},
}
</script>

<template>
	<Container :fluid="$b.m">
		<Title v-if="data.title" :title="data.title" :titleStyle="data.titleStyle" />
		<v-expansion-panels>
			<v-expansion-panel v-for="({ title, content }, n) in data.items" :key="n">
				<v-expansion-panel-header :color="$vars.primary">
					<template v-slot:actions>
						<v-icon :color="$vars.cta"> $expand </v-icon>
					</template>
					<span class="title basefont">{{ title }}</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content :color="$vars.secondary">
					<div class="content pa-4">
						{{ content }}
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</Container>
</template>

<style scoped lang="scss">
.title {
	color: var(--primarytext);
}
.content {
	color: var(--secondarytext);
}

.basefont {
	font-family: var(--basefont) !important;
}
</style>

