var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.slides.length && _vm.data.products.length)?_c('Container',{staticClass:"d-flex align-center no-mobile-py",class:[{ 'mt-n6 my-sm-0': _vm.index == 0 }, _vm.$b.d ? 'flex-row' : 'flex-column'],attrs:{"fluid":_vm.$b.m}},[_c('div',{staticClass:"pr-md-6 w100"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Carousel',_vm._b({attrs:{"loop":"","autoplay-delay":Number(_vm.data.autoplayDelay),"arrowsBgHalfRounded":"","dotContainerFullWidthMobile":_vm.data.slides.length >= 15},on:{"slideIndex":function($event){return _vm.getIndex($event)}}},'Carousel',
					_vm.$b.props({
						showDots: { mt: true },
						slidesPerPage: 1,
						showArrows: true,
						noDrag: { d: true },
					})
				,false),_vm._l((_vm.data.slides),function(item,i){return _c('div',{key:i,staticClass:"overflow-hidden",class:_vm.$b({ td: 'rounded-lg' })},[_c('SafeLink',{directives:[{name:"evt-click",rawName:"v-evt-click"}],class:_vm.$b({ td: 'rounded-lg' }),attrs:{"to":item.link,"id":item.labelId}},[_c('Media',{attrs:{"width":"100%","cover":"","src":_vm.$b({ mt: item.imgMobile, d: item.img }),"img-class":[
								_vm.$b({ td: 'rounded-lg' }),
								{ 'zoom-in': i == _vm.slideIndex && item.zoomEffect } ]}},[_c('div',{staticClass:"h100 w100 d-flex justify-center flex-column align-center",class:{
									'background-overlay': item.darkenSlide,
									'rounded-lg': _vm.$b.td,
								}},[(item.title)?_c('h2',{staticClass:"font-4 font-lg-16 font-md-7 font-weight-bold primary pa-1 text-center",class:_vm.textClass(i == _vm.slideIndex, item.textEffect, 'title')},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.subtitle)?_c('h3',{staticClass:"font-1 font-lg-8 font-md-5 font-weight-medium secondary pa-1 text-center",class:[
										_vm.textClass(i == _vm.slideIndex, item.textEffect, 'subtitle'),
										{
											'mt-2': item.title && !_vm.$b.m,
											'mt-1': item.title && _vm.$b.m,
										} ]},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e(),(item.cta)?_c('v-btn',{staticClass:"cta font-weight-bold",class:[
										_vm.btnClass(i == _vm.slideIndex, item.subtitle, item.textEffect),
										{
											'mt-5': !_vm.$b.m,
											'mt-1': _vm.$b.m,
										} ],attrs:{"xSmall":_vm.$b.m}},[_vm._v(_vm._s(item.cta))]):_vm._e()],1)])],1)],1)}),0)],1)],1),_c('div',{staticClass:"flex-shrink-0 py-6 py-md-0",style:({ width: _vm.$b({ mt: '100%', d: '330px' }) })},[_c('ProductsCarousel',_vm._b({attrs:{"dotContainerFullWidthMobile":_vm.data.products.length >= 15,"products":_vm.data.products,"list-id":_vm.listId,"arrowsBgHalfRounded":_vm.$b.mt}},'ProductsCarousel',
				_vm.$b.props({
					slidesPerPage: { m: 2, t: 3, d: 1 },
					center: { m: true, t: false, d: true },
					peek: { t: 0, d: 30 },
					gutter: { m: 5, t: 20, d: 30 },
					showDots: { mt: true },
				})
			,false))],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }