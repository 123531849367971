var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{attrs:{"fluid":_vm.$b.m}},[(_vm.data.textEnabled)?_c('Title',{staticClass:"mb-5",attrs:{"title":_vm.data.title,"titleStyle":_vm.data.titleStyle,"btn":{ link: _vm.data.link, linkText: _vm.data.linkText }}}):_vm._e(),_c('div',{staticClass:"secondary",class:_vm.$b.d && _vm.$bem('products-board-with-data')},[_c('v-row',{class:{ 'mx-2 row-border-carousel': _vm.$b.mt }},[_c('v-col',[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Carousel',_vm._b({attrs:{"loop":"","arrowsBgHalfRounded":"","dotContainerFullWidthMobile":_vm.data.products.length >= 15}},'Carousel',Object.assign({}, _vm.$b.props({
								slidesPerPage: { m: 2, t: 4, d: 6 },
								showArrows: true,
								center: { m: true },
								showDots: { mt: true },
								noDrag: { d: true },
							}),
							this.$attrs),false),_vm._l((_vm.data.products),function(product,i){return _c('div',{key:i,staticClass:"d-flex justify-center"},[_c('ProductCard',{attrs:{"small-carousel":"","product":product,"list-index":i},on:{"card:click":function($event){_vm.selectedProduct = product}}})],1)}),0)],1)],1)],1),_c('v-row',{class:{ 'mx-2 row-border-data-sheet': _vm.$b.mt }},[_c('v-col',[_c('ProductDataSheet',{attrs:{"product":_vm.selectedProduct}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }