<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	props: {
		data: Object,
	},
	methods: {
		getToLink(item) {
			if (item.linkType === 'cat') {
				return this.$shop.getShopRoute({
					categories: item.linkCat.familyUrlNames,
				})
			}

			if (item.linkType === 'brand') {
				return this.$shop.getShopRoute({ brand: item.linkBrand.urlName })
			}

			return item.link
		},
		getImage(item) {
			return item.linkType !== 'brand' ? item.img : item.linkBrand?.logo
		},
	},
	computed: {
		slides() {
			return this.data.slides.filter((s) => (s.linkType != 'brand' ? !!s.img : !!s.linkBrand?.logo))
		},
		slidesPerPageDesktop() {
			return Number(this.data.slidesPerPageDesktop) || 6
		},
	},
}
</script>

<template>
	<Container
		class="no-mobile-px"
		v-if="slides"
		:class="[$bem('images-carousel'), { 'images-carousel--darken-images': $b.d && data.darkenImages }]"
	>
		<Title :title="data.title" :titleStyle="data.titleStyle" v-if="data.title" />
		<v-row>
			<v-col>
				<LazyHydrate when-visible>
					<Carousel
						loop
						:autoplay-delay="Number(data.autoplayDelay)"
						v-bind="
							$b.props({
								feather: {
									d: slides.length > this.slidesPerPageDesktop && 30,
									t: slides.length > 4 && 100,
									m: slides.length > 2 && 20,
								},
								showArrows: {
									d: slides.length > this.slidesPerPageDesktop && true,
									t: slides.length > 4 && true,
								},
								slidesPerPage: { m: 2, t: 4, d: this.slidesPerPageDesktop },
								peek: {
									d: slides.length > this.slidesPerPageDesktop && 60,
									t: slides.length > 4 && 30,
									m: slides.length > 2 && 40,
								},
								showDots: { mt: true },
								gutter: 0,
								paginateBySlide: { d: true },
								noDrag: { d: true },
							})
						"
					>
						<div v-for="(item, i) of slides" :key="i" class="px-2 px-sm-3">
							<SafeLink :to="getToLink(item)">
								<!-- TEXTO ARRIBA -->
								<div
									v-if="item.textEnabled && item.textPosition == 'above'"
									class="w100 text-center my-2"
									:class="$bem('__text-above')"
								>
									<h3 class="font-weight-bold font-1 strong">
										{{ item.textTitle }}
									</h3>
									<h4
										v-if="item.textSubtitle"
										:class="{ 'subtitle-mobile': $b.mt }"
										class="font-weight-medium font-0 base--text"
									>
										{{ item.textSubtitle }}
									</h4>
								</div>
								<Media
									:src="getImage(item)"
									width="100%"
									:class="$bem('__img')"
									:imgClass="[
										data.imgBorder == 'border'
											? 'rounded-lg'
											: data.imgBorder == 'circle' && 'img-circle',
									]"
								>
									<div
										v-if="item.textEnabled"
										class="h100"
										:class="[{ 'd-flex flex-column justify-center': item.textPosition == 'over' }]"
									>
										<!-- TEXTO DENTRO -->
										<div
											v-if="item.textPosition == 'inside'"
											class="w100 h100 background-overlay pa-5 d-flex flex-column justify-center align-center white--text"
											:class="[
												$bem('__text-inside'),
												data.imgBorder == 'circle'
													? 'img-circle'
													: data.imgBorder == 'border' && 'rounded-lg',
											]"
										>
											<div class="text-center">
												<h3 class="font-weight-bold font-1">
													{{ item.textTitle }}
												</h3>
												<h4
													v-if="item.textSubtitle"
													:class="{ 'subtitle-mobile': $b.mt }"
													class="font-weight-medium font-0 mt-1"
												>
													{{ item.textSubtitle }}
												</h4>
											</div>
										</div>
										<!-- TEXTO SEO -->
										<div v-else class="seo-text" :class="$bem('__text-seo')">
											<div>
												<h3>{{ item.textTitle }}</h3>
												<h4 v-if="item.textSubtitle">{{ item.textSubtitle }}</h4>
											</div>
										</div>
									</div>
								</Media>
								<!-- TEXTO ABAJO -->
								<div
									v-if="item.textEnabled && item.textPosition == 'below'"
									class="w100 text-center my-2"
								>
									<h3 class="font-weight-bold font-1 strong">
										{{ item.textTitle }}
									</h3>
									<h4
										v-if="item.textSubtitle"
										:class="{ 'subtitle-mobile': $b.mt }"
										class="font-weight-medium font-0 base--text"
									>
										{{ item.textSubtitle }}
									</h4>
								</div>
							</SafeLink>
						</div>
					</Carousel>
				</LazyHydrate>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped lang="scss">
.background-overlay {
	background-color: #424242aa;
}

::v-deep .img-circle {
	border-radius: 50%;
}

.images-carousel {
	&--darken-images {
		& .images-carousel__img ::v-deep {
			& img {
				transition: filter 0.3s ease;
				filter: grayscale(1);
			}
			&:hover img {
				filter: grayscale(0);
			}
		}
	}
}
</style>

