var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100 w100"},[_c('div',{ref:"container",style:(_vm.containerStyler)},[_c('div',{ref:"parentDiv",style:(Object.assign({}, {position: 'absolute',
				top: 0,
				left: 0,
				transformOrigin: '0 0'},
				_vm.parentDivStyler))},[_c('Media',{attrs:{"src":_vm.hotspotData.image,"width":_vm.fullWidth ? '100%' : ''}},[_vm._l((_vm.hotspotData.dotsPositions),function(item,i){return _c('v-menu',{key:i,attrs:{"offset-x":"","offset-y":"","offset-overflow":"","max-width":_vm.$b.m ? '124px' : '224px',"max-height":_vm.$b.m ? '160px' : '250px'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"dot-container d-flex justify-center align-center",style:(_vm.getDotStyle(item))},'div',attrs,false),on),[_c('span',{staticClass:"hotspot-selector-dots",attrs:{"id":("dot-" + (i + 1))}})])]}}],null,true)},[_c('ProductCard',{attrs:{"product":_vm.hotspotData.products[i],"list-index":i,"square":""}})],1)})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }