var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.src || _vm.data.video)?_c('Container',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"once":true}}],attrs:{"fluid":""}},[_c('RatioBox',{staticClass:"grey",staticStyle:{"position":"relative"},attrs:{"width":"100%","aspectRatio":1264 / 609}},[_c('div',{staticClass:"video-container"},[_c('div',{staticClass:"video-container"},[_c('div',{staticClass:"background-overlay",style:({
						'background-color': _vm.data.overlayColor || '#fff',
						opacity: _vm.data.overlayOpacity || 0,
					})}),(!_vm.isSelfHosted)?_c('iframe',{attrs:{"id":"video-background","src":_vm.src,"frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}}):_c('Media',{staticStyle:{"width":"100%"},attrs:{"id":"video-background","src":_vm.data.host === 'mediaLibrary' ? _vm.data.video : _vm.data.src,"video-props":{
						videoAttrs: {
							controls: false,
							autoplay: 'autoplay',
							muted: 'muted',
							loop: 'loop',
						},
					},"youtube-video-props":{
						iframeAttrs: {
							allow: 'autoplay; encrypted-media',
							allowfullscreen: 'allowfullscreen',
						},
						urlOptions: {
							controls: 0,
							showinfo: 0,
							rel: 0,
							autoplay: 1,
							mute: 1,
							loop: 1,
							playlist: _vm.data.video.data.videoId,
						},
					},"vimeo-video-props":{
						iframeAttrs: {
							allow: 'autoplay; encrypted-media',
							allowfullscreen: 'allowfullscreen',
						},
						urlOptions: {
							background: 1,
							muted: 1,
							autoplay: 1,
						},
					}}}),_c('div',{staticClass:"overlay-content"},[_c('h3',{staticClass:"font-2 font-sm-5 font-md-7 font-weight-bold white--text",class:{ 'title-active': _vm.isIntersecting }},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),(_vm.data.subtitle)?_c('h4',{staticClass:"font-1 font-sm-3 font-md-5 font-weight-medium white--text",class:{ 'subtitle-active': _vm.isIntersecting }},[_vm._v(" "+_vm._s(_vm.data.subtitle)+" ")]):_vm._e(),_c('v-btn',{staticClass:"cta font-weight-bold mt-5",class:{ 'btn-active': _vm.isIntersecting, 'subtitle-active': _vm.isIntersecting },attrs:{"to":_vm.data.link,"small":_vm.$b.m}},[_vm._v(_vm._s(_vm.data.cta))])],1)],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }