<script>
export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
	computed: {
		fluid() {
			// not mobile and has one banner
			return !this.$b.m && this.data.rows?.length == 1 && this.data.rows[0].banners.length == 1
		},
	},
}
</script>

<template>
	<Container :fluid="fluid">
		<v-row v-for="(row, n) in data.rows" :key="n">
			<v-col cols="12" :md="12 / row.banners.length" v-for="(banner, n) in row.banners" :key="n">
				<BannerItem v-bind="banner" />
			</v-col>
		</v-row>
	</Container>
</template>
